@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#app {
  width: 100%;
  height: 100%;
}

body {
  @apply font-roboto;
}

input:checked::after {
  content: "✓";
  @apply text-white;
  @apply text-3xl;
  @apply -top-3;
  @apply -left-1;
  @apply relative;
}

.center {
  margin: 0 auto;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.mux-video-player {
  max-width: 100%;
  max-height: 480px;
}
